.notifScreen {
    background: var(--headerBackgroundColor);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* header */
.notifScreen header {
    align-items: center;
    background: var(--headerBackgroundColor);
    color: var(--headerColor);
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.7rem 2rem;
    font-size: 0.7rem;
    position: relative;
    top: 1.1rem;
    z-index: 0;
}

.notifScreen header div {
    display: flex;
    align-items: center;
}

.notifScreen header h1 {
    margin: 0;
    font-size: 1.9rem;
}

.notifScreen header a {
    background: none;
    border: none;
}

.notifScreen header a i {
    color: #fff;
    font-size: 1.8rem;
    margin-right: 0.9rem;
}


/* main */
.notifScreen main {
    background: var(--bodyBackgroundColor);
    border-top: 1px solid var(--bodyColor);
    border-top-left-radius: 1.4rem;
    color: var(--bodyColor);
    flex-grow: 1;
    overflow: auto;
    padding-top: 0.5rem;
    z-index: 1;
}

.notifScreen .empty {
    text-align: right;
    margin-right: 0.8rem;
    margin-bottom: 0.4rem;
    margin-top: 0.1rem;
}

.notifScreen .empty a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
}


.notifScreen .notFoundMessage {
    font-style: italic;
    text-align: center;
}

.notifScreen main > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.notifScreen main > ul li:nth-child(2n+1) {
    background: var(--nthChildLiBackground);
}

.notifScreen main > ul li {
    display: flex;
    padding: 0.4rem 0.8rem;
    align-items: center;
}

.notifScreen main > ul li i {
    font-size: 1.3rem;
}

.notifScreen main > ul li a {
    cursor: pointer;
    flex-grow: 1;
    margin: 0 1rem;
    text-decoration: none;
}

.notifScreen main > ul li strong {
    vertical-align: middle;
}

.notifScreen .isNew {
    background: var(--newNotifColor);
    border-radius: 0.4rem;
    color: #fff;
    padding: 0.1rem 0.4rem;
    font-size: 0.6rem;
    margin-left: 0.2rem;
}


.notifScreen main > ul li .close {
    background: none;
    border: none;
}

.notifScreen main > ul li .close i {
    font-size: 1rem;
}


.notifScreen .bottomModalAppUpdate {
    padding: 1rem;
}
