.bottomModal {
    background: var(--bodyBackgroundColor);
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    bottom: 0;
    box-shadow: 0rem 0rem 0.4rem 0.1rem #0a101e;
    display: flex;
    flex-direction: column;
    left: 0;
    max-height: 80%;
    position: fixed;
    right: 0;
    z-index: 1;
}

.bottomModal .bottomModal-title {
    text-align: center;
    padding: 0.8rem 0;
}

.bottomModal .bottomModal-title > strong {
    display: inline-block;
    font-size: 1.5rem;
    max-width: calc(100% - 4.8rem);
}

.bottomModal .subTitle {
    font-weight: normal;
}


.bottomModal .removeBtn {
    background: none;
    border: none;
    position: absolute;
    top: 0.7rem;
    right: 1rem;
}


.bottomModal .removeBtn i {
    font-size: 1.4rem;
}

.bottomModal-body {
    overflow: auto;
}
