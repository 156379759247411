.paramScreen {
    background: var(--headerBackgroundColor);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* header */
.paramScreen header {
    align-items: center;
    background: var(--headerBackgroundColor);
    color: var(--headerColor);
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.7rem 2rem;
    font-size: 0.7rem;
    position: relative;
    top: 1.1rem;
    z-index: 0;
}

.paramScreen header div {
    display: flex;
    align-items: center;
}

.paramScreen header h1 {
    margin: 0;
    font-size: 1.9rem;
}

.paramScreen header h1 i {
    font-size: 0.3rem;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2rem;
}

.paramScreen header h1 span {
    color: #e0dddd;
    display: inline-block;
    font-size: 1rem;
    vertical-align: middle;
}

.paramScreen header a {
    background: none;
    border: none;
}

.paramScreen header a i {
    color: #fff;
    font-size: 1.8rem;
    margin-right: 0.9rem;
}


/* main */
.paramScreen main {
    background: var(--bodyBackgroundColor);
    border-top: 1px solid var(--bodyColor);
    border-top-left-radius: 1.4rem;
    color: var(--bodyColor);
    flex-grow: 1;
    overflow: auto;
    padding-top: 0.5rem;
    z-index: 1;
}


.menu-param {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.menu-param li a {
    border-bottom: 1px solid #dad5d5;
    display: block;
    cursor: pointer;
    color: var(--bodyColor);
    padding: 1rem 1rem;
    text-decoration: none;
}

.menu-param button {
    background: var(--buttonBackgroundColor);
    border: 0;
    border-radius: 0.6rem;
    color: var(--buttonColor);
    margin-left: 1rem;
    margin-top: 1rem;
    padding: 0.5rem 0.7rem;
}

.paramScreen .changePasswordContainer {
    margin: 0 0.8rem 0.8rem;
}

.paramScreen .changePasswordContainer .errorMsg {
    color: red;
}

.paramScreen .changePasswordContainer input {
    width: 100%;
    box-sizing: border-box;
}

.paramScreen .changePasswordContainer label {
    margin-top: 0.7rem;
    display: block;
    margin-bottom: 0.4rem;
    font-weight: bold;
}


.paramScreen .changePasswordContainer button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
    margin-top: 1rem;
    padding: 0.6rem;
    font-size: 1rem;
    width: 100%;
}

.paramScreen .newUserContainer {
    margin: 0 0.8rem 0.8rem;
}

.paramScreen .newUserContainer .errorMsg {
    color: red;
}

.paramScreen .newUserContainer input {
    width: 100%;
    box-sizing: border-box;
}

.paramScreen .newUserContainer label {
    margin-top: 0.7rem;
    display: block;
    margin-bottom: 0.4rem;
    font-weight: bold;
}


.paramScreen .newUserContainer button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
    margin-top: 1rem;
    padding: 0.6rem;
    font-size: 1rem;
    width: 100%;
}

.paramScreen .homeDisplayParams {
    margin: 0 0.8rem 0.8rem;
}

.paramScreen .homeDisplayParams .moreInfo {
    display: block;
    font-size: 0.8rem;
}

.paramScreen .homeDisplayParams select {
    border-radius: 0.5rem;
    border: 1px solid #cfcaca;
    margin-top: 1rem;
    padding: 0.6rem 0.5rem;
    text-align: center;
    width: 100%;
}

.paramScreen .createNotifContainer {
    padding: 1rem;
}

.paramScreen .createNotifContainer > div {
    align-items: center;
    display: flex;
}

.paramScreen .createNotifContainer select {
    border-radius: 0.5rem;
    border: 1px solid #cfcaca;
    flex-grow: 1;
    padding: 0.6rem 0.5rem;
    text-align: center;
    margin-left: 1rem;
}

/* tinymce */
.paramScreen .createNotifContainer > div > div {
    margin-top: 1rem;
    width: 100%;
}


.paramScreen .createNotifContainer > button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
    margin-top: 1rem;
    padding: 0.6rem;
    font-size: 1rem;
    width: 100%;
}
