
.scanContainer {
    background: var(--headerBackgroundColor);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* header */
.scanContainer header {
    align-items: center;
    background: var(--headerBackgroundColor);
    color: var(--headerColor);
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.7rem 2rem;
    font-size: 0.7rem;
    position: relative;
    top: 1.1rem;
    z-index: 0;
}

.scanContainer header div {
    display: flex;
    align-items: center;
}

.scanContainer header h1 {
    margin: 0;
    font-size: 1.9rem;
}

.scanContainer header a {
    background: none;
    border: none;
}

.scanContainer header a i {
    color: #fff;
    font-size: 1.8rem;
    margin-right: 0.9rem;
}

/*main*/
.scanContainer main {
    background: var(--bodyBackgroundColor);
    border-top: 1px solid var(--bodyColor);
    border-top-left-radius: 1.4rem;
    color: var(--bodyColor);
    flex-grow: 1;
    overflow: auto;
    padding: 0.5rem 1rem 0;
    z-index: 1;
}

.scanContainer main p.subTitle:not(:first-of-type) {
    margin-bottom: 0;
}



.scanContainer .inputContainer {
    position: relative;
}

.scanContainer main .inputContainer input {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
}

.scanContainer main .inputContainer span {
    font-size: 0.9rem;
    font-weight: bold;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
}

.scanContainer main .inputContainer span.error {
    color: red;
}

.scanContainer main .inputContainer span.success {
    color: green;
}


/* video */
.scanContainer .viewportScan {
    background: #242424;
    border-radius: 0.5rem;
    height: auto;
    min-height: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

#reader {
    background: #242424;
    border-radius: 0.5rem;
    height: auto;
    position: relative;
    width: 100%;
}

/*
.scanContainer .viewport {
    background: #242424;
    border-radius: 0.5rem;
    height: auto;
    position: relative;
    width: 100%;
}

.scanContainer .viewport video {
    width: 100%;
}

.scanContainer .viewport canvas {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
*/
.scanContainer .startVideoContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #242424;
    z-index: 1;

}

.scanContainer .startVideoContainer button {
    bottom: 0;
    color: initial;
    height: 2.4rem;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 9.5rem;
}

.scanContainer .startVideoContainer span {
    bottom: 0;
    color: #fff;
    height: 1rem;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
}

.scanContainer .errorMsg {
    color: red;
    font-size: 0.8rem;
    font-weight: bold;
    height: 1rem;
    margin: 0;
    margin-bottom: 0.4rem;
}

.scanContainer .searchList {

}


.scanContainer .searchBook {
    display: flex;
    margin-bottom: 0.7rem;
}

.scanContainer .searchBook .imageSearchBook {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.4rem;
    cursor: pointer;
    height: 7rem;
    margin-right: 0.7rem;
    width: 4.9rem;
}

.scanContainer .searchBook > div:last-of-type {
    flex-grow: 1;
    flex-basis: 0;
}

.scanContainer .searchBook .bestResult {
    background: #cd563f;
    padding: 0.1rem 0.6rem;
    display: inline-block;
    border-radius: 0.2rem;
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}

.scanContainer .searchBook strong {
    cursor: pointer;
    margin-bottom: 0.3rem;
    display: block;
}

.scanContainer .goBackOverview {
    background: none;
    border: none;
    font-size: 1.6rem;
    left: 1rem;
    position: absolute;
}
