.bookScreen {
    background: var(--bodyBackgroundColor);
    color: var(--bodyColor);
}

.bookScreen > .imageContainer {
    aspect-ratio: 16/22;
    background-size: cover;
    border-bottom-left-radius: 3.5rem;
    height: 46vh;
    position: relative;
    width: 100%;
}

.bookScreen > .imageContainer > div {
    position: absolute;
    bottom: 1.8rem;
    right: 0;
    text-align: right;
}

.bookScreen > .imageContainer span {
    background: #ffffffb8;
    padding: 0.3rem 0.6rem;
    font-size: 1.2rem;
    display: inline-block;
    color: #000;
}

.bookScreen > .imageContainer > div span:nth-of-type(2) {
    margin-top: 0.4rem;
}

.bookScreen .navButtons {
    align-items: end;
    bottom: 1.1rem;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    z-index: 1;
}

.bookScreen .navButtons button ~ button {
    margin-top: 0.7rem;
}

.bookScreen .navButtons button {
    background: var(--buttonBackgroundColor);
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
    border: 0;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: #fff;
    padding: 0.5rem 1rem;
}

.bookScreen .navButtons button i {
    font-size: 1.2rem;
}

.bookActions {
    position: relative;
    bottom: 1rem;
    right: 1.3rem;
    width: auto;
    text-align: right;
}

.bookActions button {
    align-items: center;
    border: none;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: initial;
    cursor: pointer;
    display: inline-flex;
    font-size: 0.8rem;
    justify-content: center;
    padding: 0.6rem 0.6rem;
    position: relative;
    text-decoration: none;
}

.bookActions button ~ button {
    margin-left: 2rem;
}

.bookActions img {
    width: 1.3rem;
}

.bookActions i {
    font-size: 1.1rem;
    display: flex;
}

.bookActions i.bi-tag-fill {
    transform: rotate(90deg);
}

.bookActions span {
    color: var(--bodyColor);
    position: absolute;
    white-space: nowrap;
}

.bookActions img ~ span {
    top: 38px;
}

.bookActions i ~ span {
    top: 39px;
}

.bookScreen .body {
    margin: 0 1rem;
}

.bookScreen .body > div {
    margin-top: 0.8rem;
}

.bookScreen h1 {
    font-size: 1.6rem;
}

.bookScreen .body strong {
    font-size: 1.2rem;
}

.bookScreen .subTitle ~ div {
    margin-top: 0.2rem;
}

.bookScreen .dateSection > div {
    display: inline-block;
    vertical-align: top;
    width: 33%;
}

.bookScreen .linkSection {
    display: flex;
    justify-content: center;

}

.bookScreen .linkSection a:not(:last-of-type) {
    margin-right: 2rem;
}

.bookScreen .seriesBooks {
    overflow-x: auto;
    display: flex;
}

.bookScreen .addSeriesBooks {
    background: none;
    border: none;
}

.bookScreen .seriesBooks .bookItem {
    margin: 0.4rem;
}


.bookScreen .peopleLikeBooks {
    display: grid;
    grid-gap: 0.7rem;
    grid-template-columns: repeat(auto-fill, minmax(6.2rem, 1fr));
    margin-top: 0.7rem;
}

.bookScreen .moreOptions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.bookScreen .moreOptions-list li {
    align-items: center;
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer;
    display: flex;
    padding: 0.7rem 0.9rem;
}

.bookScreen .moreOptions-list li i {
    font-size: 1rem;
    margin-right: 0.7rem;
}


.bookScreen .moreOptions-list li span {
    color: grey;
    display: block;
    font-size: 0.9rem;
}


.bookScreen .formCompleteLink {
    margin-bottom: 1rem;
    text-align: center;
}
