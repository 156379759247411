
.authorSkeleton {
    user-select: none;
    background: var(--bodyBackgroundColor);

}

.authorSkeleton > .imageContainer,
.authorSkeleton h1,
.authorSkeleton .body strong,
.authorSkeleton .descriptionSection div {
  background-color: var(--skeletonColor);
  border-radius: 0.4rem;
  color: var(--skeletonColor);
  min-height: 1rem;
}

.authorSkeleton > .imageContainer {
    aspect-ratio: 16/22;
    border-bottom-left-radius: 3.5rem;
    height: 46vh;
    position: relative;
    width: 100%;
}


.authorSkeleton .body {
    margin: 0 1rem;
}

.authorSkeleton h1 {
    height: 1.6rem;
    margin-top: 4rem;
    width: 10rem;
}

.authorSkeleton strong {
    display: inline-block;
    width: 4.5rem;
    height: 1rem;
}

.authorSkeleton span {
    display: inline-block;
    width: 4.5rem;
    height: 1rem;
    margin-top: 0.3rem;
}

.authorSkeleton .descriptionSection div {
    margin-top: 0.3rem;
    height: 20rem;
}
