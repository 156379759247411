.explanation {
    background: #242424;
    color: #fff;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.explanation > footer {
    text-align: center;
    margin-bottom: 2rem;

}

.explanation > footer button {
    color: black;
    padding: 0.3rem 1.1rem;
    border: 0;
    border-radius: 0.2rem;
    margin: 0 0.5rem;
    font-size: 1rem;
}

.explanation > footer .goBack {
    background: #b5b5b5;
}

.explanation .stepExplanation {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 2.3rem;
    text-align: center;
}

.explanation .stepExplanation > .imageExplanation {
    height: 25rem;
    position: relative;
    width: 12rem;
}

.explanation .imageExplanation img {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.explanation .imageExplanation img:first-of-type {
    z-index: 1;
}

.explanation .imageExplanation img:last-of-type {
    left: 0.9rem;
    top: 1.6rem;
    width: calc(100% - 1.8rem);
    z-index: 0;
}

.explanation .imageExplanation .topApp {
    background: #242424;
    height: 1rem;
    position: absolute;
    top: 1rem;
    width: 100%;
    z-index: 1;
}

.explanation .imageExplanation .bottomApp {
    background: #fff;
    bottom: 1.5rem;
    height: 0.4rem;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 10.4rem;
}

.explanation .iconExplanation {
    font-size: 4rem;
    margin-bottom: 2.7rem;
    margin-top: 4rem;
}

.explanation .target {
    background: transparent;
    width: 4.3rem;
    height: 4.3rem;
    border-radius: 50%;
    position: absolute;
    border: 6px solid #b4359b;
    z-index: 2;
}

.explanation .arrowTarget {
    position: absolute;
    z-index: 1;
}

.explanation .arrowTarget i {
    color: #e24f4f;
    font-size: 5rem;
}
