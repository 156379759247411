.tagItem {
    font-weight: bold;
    display: inline-block;
    background: grey;
    color: #fff;
    padding: 0.3rem 0.6rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    margin: 0.2rem 0.2rem;
}

.tagItem i {
    margin-right: 0.3rem;
}
