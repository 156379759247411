.connectionContainer {
  margin: 3rem 1rem 0 1rem;
  background: var(--bodyBackgroundColor);
  color: var(--bodyColor);
}

.connectionContainer h1 {
    margin-bottom: 0.6rem;
}

.connectionContainer input {
    background: var(--inputBackgroundColor);
    color: var(--inputColor);
    border: 1px solid var(--inputBorderColor);
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    width: 100%;
}

.connectionContainer input:first-of-type {
    margin-top: 0.8rem;
}


.connectionContainer button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: #fff;
    margin-top: 1rem;
    padding: 0.6rem;
    font-size: 1.1rem;
    width: 100%;
}

.connectionContainer .errorMsg {
    color: #d02222;
    font-weight: bold;
}
