/* Modal changer d'étagères */
.shelves-list {
    margin: 0;
    padding: 0 0 1rem;
    list-style-type: none;
}

.shelves-list li {
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0.7rem 0.9rem;
}

.shelves-list li i {
    font-size: 1rem;
}


.shelves-list-option {
    display: block;
    margin: auto;
    text-align: center;
    cursor: pointer;
}

.shelves-list-option input {
    margin-right: 0.6rem;
}
