.statsScreen {
    background: var(--headerBackgroundColor);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* header */
.statsScreen header {
    align-items: center;
    background: var(--headerBackgroundColor);
    color: var(--headerColor);
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.7rem 2rem;
    font-size: 0.7rem;
    position: relative;
    top: 1.1rem;
    z-index: 0;
}

.statsScreen header div {
    display: flex;
    align-items: center;
}

.statsScreen header h1 {
    margin: 0;
    font-size: 1.9rem;
}

.statsScreen header h1 i {
    font-size: 0.3rem;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2rem;
}

.statsScreen header h1 span {
    color: #e0dddd;
    display: inline-block;
    font-size: 1rem;
    vertical-align: middle;
}

.statsScreen header a {
    background: none;
    border: none;
}

.statsScreen header a i {
    color: #fff;
    font-size: 1.8rem;
    margin-right: 0.9rem;
}


/* main */
.statsScreen main {
    background: var(--bodyBackgroundColor);
    border-top: 1px solid var(--bodyColor);
    border-top-left-radius: 1.4rem;
    color: var(--bodyColor);
    flex-grow: 1;
    overflow: auto;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.8rem;
    z-index: 1;
}



.statsScreen main .menu-stats {
    font-size: 1.2rem;
    padding: 0;
    margin: 0 0 1.3rem;
    list-style-type: none;
    overflow-x: auto;
    width: 100%;
    height: auto;
    white-space: nowrap;
}

.statsScreen main .menu-stats li {
    display: inline-block;
    cursor: pointer;
    margin: 0 0.5rem;
}

.statsScreen main .menu-stats li.selected {
    font-weight: bold;
    text-decoration: underline;
}

.statsScreen .yearBooks {
    height: calc(100% - 3.1rem);
    overflow: auto;
}


.statsScreen .month:not(:first-of-type) {
    margin-top: 1rem;
}

.statsScreen .month > strong {
    font-size: 1.2rem;
    margin-left: 0.4rem;
}


/* BookItem */
.statsScreen .booksList {
    display: grid;
    grid-gap: 0.7rem;
    grid-template-columns: repeat(auto-fill, minmax(6.2rem, 1fr));
    margin-top: 0.4rem;
    min-height: 0.9rem;
    position: relative;

}


.statsScreen .booksList .notFoundMessage {
    font-style: italic;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
}


.statsScreen .moreOptions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.statsScreen .moreOptions-list li {
    align-items: center;
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer;
    display: flex;
    padding: 0.7rem 0.9rem;
}

.statsScreen .moreOptions-list li i {
    font-size: 1rem;
    margin-right: 0.7rem;
}


.statsScreen .moreOptions-list li span {
    color: grey;
    display: block;
    font-size: 0.9rem;
}
.statsScreen .moreOptions-list input {
    margin-top: 0.3rem;
    padding: 0.2rem 0.5rem;
    border: 1px solid #a7a7a7;
    border-radius: 3px;
}
