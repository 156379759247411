.bookExtracts {
    background: var(--headerBackgroundColor);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* header */
.bookExtracts header {
    align-items: center;
    background: var(--headerBackgroundColor);
    color: var(--headerColor);
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.7rem 2rem;
    font-size: 0.7rem;
    position: relative;
    top: 1.1rem;
    z-index: 0;
}

.bookExtracts header div {
    display: flex;
    align-items: center;
}

.bookExtracts header h1 {
    margin: 0;
    font-size: 1.9rem;
}

.bookExtracts header h1 i {
    font-size: 0.3rem;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2rem;
}

.bookExtracts header h1 span {
    color: #e0dddd;
    display: inline;
    font-size: 1rem;
}

.bookExtracts header a {
    background: none;
    border: none;
    cursor: pointer;
}

.bookExtracts header a i {
    color: #fff;
    font-size: 1.8rem;
    margin-right: 0.9rem;
}


/* main */
.bookExtracts main {
    background: var(--bodyBackgroundColor);
    border-top: 1px solid var(--bodyColor);
    border-top-left-radius: 1.4rem;
    color: var(--bodyColor);
    flex-grow: 1;
    overflow: auto;
    padding: 1rem;
    z-index: 1;
}

.bookExtracts main > div {
    padding-top: 1rem;
}

.bookExtracts main > div:not(:last-of-type) {
    border-bottom: 1px solid #d0d0d0;
}

.bookExtracts main select {
    border-radius: 0.5rem;
    border: 1px solid #cfcaca;
    padding: 0.4rem 0;
    text-align: center;
    width: 100%;

}

.spoilersentence {
    cursor: pointer;
    font-style: italic;
    text-decoration: underline;
}

.secrettext {
    display: none;
}
