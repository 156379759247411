.systemMessageModal {
    background: #000000;
    border-radius: 1rem;
    bottom: 0.5rem;
    color: #fff;
    font-size: 0.9rem;
    left: 0;
    margin: auto;
    opacity: 0.7;
    padding: 0.5rem 0.8rem;
    position: fixed;
    right: 0;
    width: fit-content;
    z-index: 1;
}
