.historyParam .empty {
    text-align: right;
    margin-right: 0.8rem;
    margin-bottom: 0.4rem;
    margin-top: 0.1rem;
}

.historyParam .empty a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
}


.historyParam .notFoundMessage {
    font-style: italic;
    text-align: center;
}

.historyParam ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.historyParam li:nth-child(2n+1) {
    background: var(--nthChildLiBackground);
}

.historyParam li {
    display: flex;
    padding: 0.4rem 0.8rem;
    align-items: center;
}

.historyParam li i {
    font-size: 1.4rem;
}

.historyParam li a {
    flex-grow: 1;
    margin: 0 1.2rem;
    color: var(--bodyColor);
    text-decoration: none;
}

.historyParam li .close {
    background: none;
    border: none;
}

.historyParam li .close i {
    font-size: 1rem;
}
