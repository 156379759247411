.commentModal {
    padding: 0.1rem 1.1rem;
    text-align: center;
    margin-bottom: 1rem;
}

.commentModal textarea {
    box-sizing: border-box;
    height: 6rem;
    margin: auto;
    width: 100%;
}

.commentModal button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
    margin-top: 1rem;
    padding: 0.6rem;
    font-size: 1rem;
    width: 100%;
}
