.shelvesParam .shelvesList > div {
    margin: 0.7rem auto;
    width: calc(100% - 2rem);
}

.shelvesParam .shelf {
    color: #fff;
    display: flex;
    padding: 0;
    border-radius: 0.6rem;
    box-sizing: border-box;
}

.shelvesParam .shelf select {
    border-radius: 0.5rem;
    border: 1px solid #cfcaca;
    margin-right: 1rem;
    padding: 0.6rem 0.5rem;
    text-align: center;
    width: 3rem;
}

.shelvesParam .shelf input {
    flex-grow: 1;
    font-size: 0.8rem;
    margin-left: 1rem;
}

.shelvesParam .shelf button {
    background: none;
    border: none;
    color: var(--bodyColor);
    padding: 0;
}

.shelvesParam .shelf button i {
    font-size: 1rem;
}

.shelvesParam .shelf .arrowDirection {
    padding: 0 0.2rem;
}

.shelvesParam .shelf .deleteButton,
.shelvesParam .shelf .overShelfButton {
    margin-left: 1rem;
}

.shelvesParam .saveButton {
    align-items: center;
    background: var(--buttonBackgroundColor);
    border-radius: 50%;
    border: 0;
    bottom: 4rem;
    box-shadow: -0.1rem 0.2rem 0.3rem -0.2rem #060524a1;
    color: #fff;
    display: flex;
    font-size: 2rem;
    height: 2.6rem;
    justify-content: center;
    position: fixed;
    right: 1rem;
    width: 2.6rem;
}

.shelvesParam .addButton {
    align-items: center;
    background: var(--buttonBackgroundColor);
    border-radius: 50%;
    border: 0;
    bottom: 1rem;
    box-shadow: -0.1rem 0.2rem 0.3rem -0.2rem #060524a1;
    color: #fff;
    display: flex;
    font-size: 2rem;
    height: 2.6rem;
    justify-content: center;
    position: fixed;
    right: 1rem;
    width: 2.6rem;
}

.shelvesParam .changeShelfModal {
    margin: 0 1rem 1rem;
}

.shelvesParam .changeShelfModal select {
    border-radius: 0.5rem;
    border: 1px solid #cfcaca;
    padding: 0.6rem 0.5rem;
    text-align: center;
    width: 100%;
}

.shelvesParam .changeShelfModal button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
    margin-top: 2.1rem;
    padding: 0.6rem;
    font-size: 1rem;
    width: 100%;
}
