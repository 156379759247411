
.bookSkeleton {
    user-select: none;
    background: var(--bodyBackgroundColor);
}

.bookSkeleton > .imageContainer,
.bookSkeleton h1,
.bookSkeleton .body strong,
.bookSkeleton .descriptionSection div,
.bookSkeleton span,
.bookSkeleton .linkSection,
.bookSkeleton .themeSection div  {
  background-color: var(--skeletonColor);
  border-radius: 0.4rem;
  color: var(--skeletonColor);
  min-height: 1rem;
}

.bookSkeleton > .imageContainer {
    aspect-ratio: 16/22;
    border-bottom-left-radius: 3.5rem;
    height: 46vh;
    position: relative;
    width: 100%;
}


.bookSkeleton .body {
    margin: 0 1rem;
}

.bookSkeleton h1 {
    height: 4.2rem;
}

.bookSkeleton strong {
    display: inline-block;
    width: 4.5rem;
    height: 1rem;
}

.bookSkeleton span {
    display: inline-block;
    width: 4.5rem;
    height: 1rem;
    margin-top: 0.3rem;
}

.bookSkeleton .dateSection > div {
    display: inline-block;
    vertical-align: top;
    width: 33%;
}

.bookSkeleton .descriptionSection div {
    margin-top: 0.3rem;
    height: 13rem;
}

.bookSkeleton .linkSection {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 40%;
}
