@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=PT+Sans&family=PT+Sans+Narrow:wght@400;700&family=Raleway&family=Roboto&family=Roboto+Condensed&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

.theme.basic {
    --headerBackgroundColor: #242424;
    --bodyBackgroundColor: #fff;
    --buttonBackgroundColor: #242424;
    --nthChildLiBackground: #ececec;
    --headerColor: #fff;
    --bodyColor: #242424;
    --buttonColor: #fff;
    --linkColor: #1bb79a;
    --newNotifColor: #f9d818;
    --skeletonColor: #d6d6d685;
    --inputBackgroundColor: #fff;
    --inputColor: #000;
    --inputBorderColor: #cfcaca;
}

.theme.dark {
    --headerBackgroundColor: #242424;
    --bodyBackgroundColor: #242424;
    --buttonBackgroundColor: #000;
    --nthChildLiBackground: #4d4c4c;
    --headerColor: #fff;
    --bodyColor: #fff;
    --buttonColor: #fff;
    --linkColor: #1bb79a;
    --newNotifColor: #f9d818;
    --skeletonColor: #2d2c2c;
    --inputBackgroundColor: #000;
    --inputColor: #fff;
    --inputBorderColor: #4b4b4b;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    font-family: 'PT Sans Narrow';
    height: 100vh;
}

#root, .theme {
    background: var(--bodyBackgroundColor);
    color: var(--bodyColor);
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
}

button {
    cursor: pointer;
    font-family: 'PT Sans Narrow';
    color: var(--bodyColor);
}


a.link {
    color: var(--linkColor);
    cursor: pointer;
    text-decoration: underline;
}


.backgroundModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000ba;
    z-index: 1;
}

textarea {
    background: var(--inputBackgroundColor);
    border: 1px solid var(--inputBorderColor);
    border-radius: 0.5rem;
    color: var(--inputColor);
    padding: 0.4rem 0.5rem;
    resize: none;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    background: var(--inputBackgroundColor);
    border: 1px solid var(--inputBorderColor);
    border-radius: 0.5rem;
    color: var(--inputColor);
    padding: 0.6rem 0.5rem;
}

.bi-tag-fill:before,
.bi-tags-fill:before {
    transform: rotate(95deg);
}


.horizontalScroll::-webkit-scrollbar {
    background: transparent;
    border-radius: 2rem;
    display: block;
    height: 4px;
    width: 100%;
}

.scroll::-webkit-scrollbar {
    background: transparent;
    border-radius: 2rem;
    display: block;
    height: 100%;
    width: 4px;
}

.horizontalScroll::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 2rem;
}
