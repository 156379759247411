.authorScreen {
    background: var(--bodyBackgroundColor);
    color: var(--bodyColor);
}

.authorScreen > .imageContainer {
    aspect-ratio: 16/22;
    background-size: cover;
    border-bottom-left-radius: 3.5rem;
    height: 46vh;
    position: relative;
    width: 100%;
}

.authorScreen > .imageContainer > div {
    position: absolute;
    bottom: 1.8rem;
    right: 0;
    text-align: right;
}

.authorScreen > .imageContainer span {
    background: #ffffffb8;
    padding: 0.3rem 0.6rem;
    font-size: 1.2rem;
    display: inline-block;
}

.authorScreen > .imageContainer > div span:nth-of-type(2) {
    margin-top: 0.4rem;
}

.authorScreen .navButtons {
    align-items: end;
    bottom: 1.1rem;
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    z-index: 1;
}

.authorScreen .navButtons button ~ button {
    margin-top: 0.7rem;
}

.authorScreen .navButtons button {
    background: var(--buttonBackgroundColor);
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
    border: 0;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: #fff;
    padding: 0.5rem 1rem;
}

.authorScreen .navButtons button i {
    font-size: 1.2rem;
}

.authorActions {
    position: relative;
    bottom: 1rem;
    right: 1.3rem;
    width: auto;
    text-align: right;
}

.authorActions button {
    align-items: center;
    border: none;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: initial;
    cursor: pointer;
    display: inline-flex;
    font-size: 0.8rem;
    justify-content: center;
    padding: 0.6rem 0.6rem;
    position: relative;
    text-decoration: none;
}

.authorActions button ~ button {
    margin-left: 2rem;
}

.authorActions img {
    width: 1rem;
}

.authorActions i {
    font-size: 1.1rem;
    display: flex;
}

.authorActions span {
    color: var(--bodyColor);
    position: absolute;
    white-space: nowrap;
}

.authorActions img ~ span {
    top: 30px;
}

.authorActions i ~ span {
    top: 39px;
}

.authorScreen .body {
    margin: 0 1rem;
}

.authorScreen .body > div {
    margin-top: 0.8rem;
}

.authorScreen h1 {
    font-size: 1.6rem;
}

.authorScreen .body strong {
    font-size: 1.2rem;
}

.authorThemes {
    margin-top: 0.1rem;
}

.authorBooks {
    display: grid;
    grid-gap: 0.7rem;
    grid-template-columns: repeat(auto-fill, minmax(6.2rem, 1fr));
    margin-top: 0.5rem;
}

.authorScreen .moreOptions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.authorScreen .moreOptions-list li {
    align-items: center;
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer;
    display: flex;
    padding: 0.7rem 0.9rem;
}

.authorScreen .moreOptions-list li i {
    font-size: 1rem;
    margin-right: 0.7rem;
}


.authorScreen .moreOptions-list li span {
    color: grey;
    display: block;
    font-size: 0.9rem;
}
