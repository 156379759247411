.homeScreen {
    background: var(--headerBackgroundColor);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* header */
.homeScreen header {
    align-items: center;
    background: var(--headerBackgroundColor);
    color: var(--headerColor);
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.7rem 2rem;
    font-size: 1rem;
    position: relative;
    top: 1.1rem;
    z-index: 0;
}

.homeScreen header h1 {
    font-size: 1.9rem;
    margin: 0;
}

.homeScreen header a {
    background: none;
    border: none;
    display: inline-block;
    position: relative;
}

.homeScreen header a i {
    color: #fff;
    font-size: 1.4rem;
}

.homeScreen header a:not(:last-of-type) {
    margin-right: 1.2rem;
}

.homeScreen header a i.isNew {
    color: var(--newNotifColor);
}

.homeScreen header a .isNewIcon {
    background: var(--newNotifColor);
    border-radius: 50%;
    min-width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    bottom: -0.5rem;
    font-size: 0.6rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: -0.4rem;
    width: auto;
    padding: 0.1rem;
}

/* tags filtre */
.tagsFilter {
    position: fixed;
    bottom: 4rem;
    right: -9.5rem;
    transition: right 0.7s;
}

.tagsFilter.open {
    right: 0;
}

.tagsFilter > div {
    background: #fff;
    border-top-left-radius: 0.5rem;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    max-height: 18rem;
    overflow: auto;
    padding: 1rem 0.7rem 0.3rem;
}

.openTagFilter {
    background: #fff;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
    border: 0;
    bottom: 0rem;
    box-shadow: -0.1rem 0.2rem 0.3rem -0.2rem #060524a1;
    color: #e75921;
    left: -3rem;
    padding: 0.5rem 1rem;
    position: absolute;
}

.openTagFilter i {
    font-size: 1.2rem;
}

.openTagFilter span {
    font-size: 0.6rem;
    left: -1px;
    position: relative;
    top: 3px
}

.tag {
    border-radius: 0.4rem;
    border: 1px solid grey;
    cursor: pointer;
    font-size: 1rem;
    margin: 0 0 0.4rem;
    overflow: hidden;
    padding: 0.3rem 0.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 7.1rem;
}

.tag i {
    margin-right: 0.6rem;
}

/* barre de recherche */
.searchContainer {
    align-items: end;
    bottom: 1.1rem;
    display: flex;
    position: fixed;
    right: 0;
    z-index: 1;
}

.searchContainer.isOpen {
    left: 0;
}

.searchContainer > div {
    width: calc(100% - 4.6rem);
    margin-right: 1rem;
    margin-left: 1rem;
}

.searchContainer .filterBtn {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.searchContainer .searchFilter {
    background: var(--bodyBackgroundColor);
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    border-radius: 0.9rem;
    color: var(--bodyColor);
    margin-bottom: 1rem;
    padding: 0.8rem 0.6rem;
}

.searchContainer .searchFilter p {
    margin-top: 0;
    text-align: center;
}

.searchContainer .searchFilter label {
    cursor: pointer;
}

.searchContainer .searchFilter label input {
    margin: 0;
    vertical-align: middle;
}

.searchContainer .searchFilter label span {
    margin-left: 0.7rem;
    vertical-align: middle;
}

.searchContainer .searchFilter .fieldsSearch {
    margin-top: 0.6rem;
    padding-left: 1.4rem;
    border-left: 1px solid #b6b1b1;
    margin-left: 0.3rem;
}

.searchContainer .searchFilter .fieldsSearch strong {
    display: block;
    margin-bottom: 0.4rem;
    font-weight: bold;
}

.searchContainer .searchFilter button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
    margin-top: 1rem;
    padding: 0.3rem;
    font-size: 1rem;
    width: 100%;
}

.searchContainer .internSearchActivate {
    aspect-ratio: 1;
    background: #ecc263;
    border-radius: 50%;
    bottom: 0.4rem;
    position: absolute;
    right: 0.1rem;
    width: 0.5rem;
}

.booksByThemeBtn {
    background: #c04196;
    border-radius: 0.9rem;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    margin-bottom: 0.8rem;
    margin-right: 0.7rem;
    border: 0;
    padding: 0.3rem 0.7rem 0.4rem;
    color: #fff;
    font-size: 0.9rem;
}

.booksByThemeBtn i {
    margin-right: 0.3rem;
}

.bookByScanBtn {
    background: #4191c0;
    border-radius: 0.9rem;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    margin-bottom: 0.8rem;
    border: 0;
    padding: 0.3rem 0.7rem 0.4rem;
    color: #fff;
    font-size: 0.9rem;
}

.bookByScanBtn i {
    margin-right: 0.3rem;
}

.inputSection {
    position: relative;
    background: var(--headerBackgroundColor);
    border-radius: 0.9rem;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: var(--bodyColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputSection input {
    flex-grow: 1;
    background: transparent;
    border: 0;
    color: white;
    font-size: 0.8rem;
    padding: 0.8rem 0.7rem;
    outline: none;
}

.inputSection i {
    color: #fff;
    font-size: 1.4rem;
    margin: 0 0.7rem;
}

.searchBtn {
    background: var(--buttonBackgroundColor);
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
    border: 0;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: #fff;
    padding: 0.5rem 1rem;
}

.searchBtn i {
    font-size: 1.2rem;
}

/* main */
.homeScreen main {
    background: var(--bodyBackgroundColor);
    border-top: 1px solid var(--bodyColor);
    border-top-left-radius: 1.4rem;
    color: var(--bodyColor);
    flex-grow: 1;
    overflow: auto;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-top: 0.8rem;
    z-index: 1;
}

main .menu-shelves {
    font-size: 1.2rem;
    padding: 0;
    margin: 0 0 1.3rem;
    list-style-type: none;
    overflow-x: auto;
    width: 100%;
    height: auto;
    white-space: nowrap;
}

main .menu-shelves li {
    display: inline-block;
    cursor: pointer;
    margin: 0 0.5rem;
}

main .menu-shelves li.selected {
    font-weight: bold;
    text-decoration: underline;
}

.booksShelves {
    height: calc(100% - 3.1rem);
    overflow: auto;
}

/* shelf */
.shelf:not(:first-of-type) {
    margin-top: 1rem;
}

.shelf > strong {
    font-size: 1.2rem;
    margin-left: 0.4rem;
}

/* BookItem */
.booksList {
    display: grid;
    grid-gap: 0.7rem;
    grid-template-columns: repeat(auto-fill, minmax(6.2rem, 1fr));
    margin-top: 0.4rem;
    min-height: 0.9rem;
    position: relative;

}


.booksList .notFoundMessage {
    font-style: italic;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
}

.booksShelves .seeNextBooks,
.booksShelves .seePrevBooks {
    background: var(--buttonBackgroundColor);
    border-radius: 0.3rem;
    box-sizing: border-box;
    color: var(--buttonColor);
    font-size: 0.9rem;
    margin: 1.2rem 1rem 0;
    padding: 0.5rem;
    width: calc(100% - 2rem);
}


/* recherche */
.searchMenu {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    margin-left: 0.6rem;
}

.searchMenu strong {
    cursor: pointer;
    margin: 0 3px;
}

.searchMenu strong:not(.isSelected) {
    color: #b1b1b1;
}


.searchList {
    margin-left: 0.6rem;
}

.searchBook,
.searchAuthor {
    display: flex;
    margin-bottom: 0.7rem;
}

.searchBook .imageSearchBook,
.searchAuthor .imageSearchAuthor {
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.4rem;
    cursor: pointer;
    height: 7rem;
    margin-right: 0.7rem;
    width: 4.9rem;
}

.searchBook > div:last-of-type {
    flex-grow: 1;
    flex-basis: 0;
}

.searchBook strong {
    cursor: pointer;
    margin-bottom: 0.3rem;
    display: block;
}
