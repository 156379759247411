.bookdetail {
    padding: 0 1.2rem;
    margin-bottom: 2rem;
}

.followedAuthor {
    text-align: center;
    display: block;
    font-size: 0.9rem;
    margin-top: 3px;
    font-style: italic;
}

.bookdetail-header {
    display: flex;
}

.bookdetail-header > div:last-of-type {
    flex-grow: 1;
    flex-basis: 0;
}

.bookdetail .imageContainer {
    aspect-ratio: 16/22;
    background-size: cover;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    position: relative;
    width: 30%;
}

.bookdetail .imageContainer a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.bookdetail-title {
    margin-bottom: 0.4rem;
}

.bookdetail-title > a {
    text-decoration: none;
    color: inherit;
}

.bookdetail-title > a > strong {
    font-size: 1.2rem;
}

.bookdetail-dates {
    font-size: 1.1rem;
}

.bookdetail-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
}

.bookdetail-action {
    align-items: center;
    background: var(--bodyBackgroundColor);
    border-radius: 0.5rem;
    border: 1px solid #b3b1b1;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: var(--bodyColor);
    cursor: pointer;
    display: inline-flex;
    font-size: 0.9rem;
    justify-content: center;
    padding: 0.2rem 0.5rem;
    text-decoration: none;
}

.bookdetail-action img {
    width: 1rem;
}

.bookdetail-action i {
    font-size: 0.9rem;
    height: 1rem;
}

.bookdetail-action span {
    margin-left: 0.5rem;
}

.bookdetail-tags button,
.bookdetail-comment button {
    background: none;
    border: none;
    font-size: 1.1rem;
    padding-left: 0;
}


.bookdetail-tags strong,
.bookdetail-comment strong {
    display: inline-block;
}

.bookdetail-tags strong + i,
.bookdetail-comment strong + i {
    font-size: 0.8rem;
    vertical-align: middle;
    margin-bottom: 2px;
    display: inline-block;
}

.bookdetail-comment {
    margin-top: 1rem;
}

.deleteConfirmModal {
    margin-bottom: 1rem;
    padding: 0 1.1rem;
    text-align: center;
}

.deleteConfirmModal p {
    margin: 0;
}

.deleteConfirmModal button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: #fff;
    margin-top: 1.4rem;
    padding: 0.4rem;
    width: 100%;
}
