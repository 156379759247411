.tagParams {
    padding: 1rem 0.7rem;
}

.tagParams .addButton {
    align-items: center;
    background: var(--buttonBackgroundColor);
    border-radius: 50%;
    border: 0;
    bottom: 1rem;
    box-shadow: -0.1rem 0.2rem 0.3rem -0.2rem #060524a1;
    color: #fff;
    display: flex;
    font-size: 2rem;
    height: 2.6rem;
    justify-content: center;
    position: fixed;
    right: 1rem;
    width: 2.6rem;
}

.tagParams .tagItem {
    cursor: pointer;
}


.tagParams form {
    padding: 0 0.9rem 0.7rem;
}

.tagParams form input {
    box-sizing: border-box;
    margin-right: 1rem;
    padding: 0.6rem;
    width: 100%;
}

.tagParams button[type="submit"] {
    margin-top: 1rem;
    padding: 0.4rem;
    width: 100%;
}

.tagParams button[type="submit"] {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
}
