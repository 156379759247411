.bookItem {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    text-align: left;
    /* margin: 0.4rem; */
    padding: 0;
    flex-shrink: 0;
}

.bookItem .imageContainer {
    background-size: cover;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    width: 100%;
}

.aspectRatio {
    aspect-ratio: 16/22;
}

.bookItem .imageContainer div {
    position: absolute;
    bottom: 0.4rem;
    right: 0.4rem;
    text-align: right;
}

.bookItem .imageContainer i {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.8rem;
    height: 1.4rem;
    justify-content: center;
    margin-left: 0.4rem;
    width: 1.4rem;
}

i.hasComment {
    color: #49c1e3;
}

i.hasTags {
    color: #e75921;
}


.bookItem .imageContainer .next {
    background: #fd2cb4;
    border-radius: 0.3rem;
    color: #fff;
    display: block;
    font-size: 0.6rem;
    margin-top: 0.4rem;
    padding: 0.1rem 0.3rem;
}

.bookItem .imageContainer .shelf {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: #000;
    display: inline-block;
    font-size: 0.7rem;
    justify-content: center;
    padding: 0.1rem 0.5rem;
    white-space: nowrap;
}

.bookItem .imageContainer .shelf i {
    display: initial;
    height: auto;
    margin-left: 0;
    margin-right: 0.3rem;
    padding: 0;
    vertical-align: middle;
    width: auto;
}

.bookItem .imageContainer .shelf span {
    display: inline-block;
    line-height: 1.5;
    max-width: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.subTitle > div {
    margin-bottom: 0.3rem;
    align-items: center;
    border: none;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0rem 0rem 0.2rem 0rem #060524a1;
    color: initial;
    cursor: pointer;
    display: inline-flex;
    font-size: 0.7rem;
    justify-content: center;
    padding: 0.5rem 0.5rem;
    position: relative;
    text-decoration: none;
}

.subTitle img {
    width: 0.9rem;
}
