.tagsModal {
    padding: 0 1.1rem;
    text-align: center;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
}

.tags-list {
    flex-grow: 1;
    margin: 0;
    max-height: calc(80vh - 12.1rem);
    overflow: auto;
    padding: 0;
}

.tags-list li {
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0.9rem;
    height: 1.9rem;
    align-content: center;
    align-items: center;
}

.tags-list li i {
    font-size: 1.1rem;
}

.tagsModal footer {
    margin-top: 1.8rem;
}

.tagsModal footer > div {
    display: flex;
    justify-content: space-between;
}

.tagsModal button {
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: var(--buttonColor);
}

.tagsModal input {
    box-sizing: border-box;
    flex-grow: 1;
    margin-right: 1rem;
    padding: 0.4rem;
}

.tagsModal button.pushNewTag {
    padding: 0.4rem 0.5rem;
    font-size: 1rem;
}

.tagsModal button.sendBtn {
    margin-top: 1rem;
    padding: 0.6rem;
    font-size: 1rem;
    width: 100%;
}
