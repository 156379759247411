
.homeBookSkeleton {
    background: var(--bodyBackgroundColor);
    user-select: none;
}

.homeBookSkeleton .books {
    display: grid;
    grid-gap: 0.7rem;
    grid-template-columns: repeat(auto-fill, minmax(6.2rem, 1fr));
    margin-top: 0.4rem;
    height: 10.1rem;
}

.homeBookSkeleton .menu {
    display: flex;
    margin: 0.5rem;
}

.homeBookSkeleton .menu > div {
    height: 1.6rem;
    margin-right: 1rem;
    width: 5rem;
}

.homeBookSkeleton .shelfName {
    height: 1.6rem;
    width: 5rem;
}

.homeBookSkeleton .books > div,
.homeBookSkeleton .menu > div,
.homeBookSkeleton .shelfName {
    background-color: var(--skeletonColor);
    border-radius: 0.4rem;
}
