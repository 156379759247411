
.hello {
    text-align: center;
    background: #242424;
    color: #fff;
    padding: 0 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hello h1 {
    display: block;
    font-size: 4rem;
    text-align: center;
}

.hello p {
    font-size: 1.2rem;
    margin: 1.4rem auto;
    text-align: center;

}
.hello p:first-of-type {
    width: 14rem;
}

.hello footer {
    margin-top: 4rem;
}

.hello footer button {
    border-radius: 0.6rem;
    color: black;
    font-size: 1.1rem;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;

}

.hello footer a {
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: underline;
}
