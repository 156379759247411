.searchByTheme {
    background: var(--headerBackgroundColor);
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* header */
.searchByTheme header {
    align-items: center;
    background: var(--headerBackgroundColor);
    color: var(--headerColor);
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.7rem 2rem;
    font-size: 0.7rem;
    position: relative;
    top: 1.1rem;
    z-index: 0;
}

.searchByTheme header div {
    display: flex;
    align-items: center;
}

.searchByTheme header h1 {
    margin: 0;
    font-size: 1.9rem;
}

.searchByTheme header h1 i {
    font-size: 0.3rem;
    margin-right: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.2rem;
}

.searchByTheme header h1 span {
    color: #e0dddd;
    display: inline;
    font-size: 1rem;
}

.searchByTheme header a {
    background: none;
    border: none;
}

.searchByTheme header a i {
    color: #fff;
    font-size: 1.8rem;
    margin-right: 0.9rem;
}


/* main */
.searchByTheme main {
    background: var(--bodyBackgroundColor);
    border-top-left-radius: 1.4rem;
    color: var(--bodyColor);
    flex-grow: 1;
    overflow: auto;
    padding: 1rem;
    z-index: 1;
}

.searchByTheme ul {
    font-size: 1.2rem;
    padding: 0 0 0.3rem;
    margin: 0 0 1.3rem;
    list-style-type: none;
    overflow-x: auto;
    width: 100%;
    height: auto;
    white-space: nowrap;
}

.searchByTheme ul li {
    display: inline-block;
    cursor: pointer;
    margin: 0 0.5rem;
}

.searchByTheme ul li.selected {
    font-weight: bold;
    text-decoration: underline;
}

.selectedThemeMessage {
    font-style: italic;
    text-align: center;
}

.searchByTheme footer {
    display: flex;
}

.searchByTheme footer > span {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 1rem;
    vertical-align: middle;
    white-space: nowrap;
}

.searchByTheme footer button {
    width: 50%;
    align-items: center;
    background: var(--buttonBackgroundColor);
    border-radius: 0.4rem;
    border: 0;
    color: #fff;
    display: flex;
    font-size: 1rem;
    justify-content: center;
    padding: 0.3rem;
}

.searchByTheme footer button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.searchByTheme footer button ~ button {
    margin-left: 1rem;
}

.searchByTheme footer button span {
    margin: 0 0.2rem;
}
