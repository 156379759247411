.searchBooksSkeleton {
    background: var(--bodyBackgroundColor);
    margin-left: 1rem;
    margin-top: 1rem;
    user-select: none;
}

.searchBooksSkeleton > .title {
    display: flex;
}

.searchBooksSkeleton .title > div {
    height: 1.6rem;
    margin-right: 1rem;
    width: 5rem;
}

.searchBooksSkeleton .items > div {
    display: flex;
    margin-top: 1rem;
}

.searchBooksSkeleton .items .image {
    height: 7rem;
    width: 4.9rem;
}

.searchBooksSkeleton .items .content {
    flex-grow: 1;
    margin-left: 1rem;
}

.searchBooksSkeleton .items .title {
    height: 1.6rem;
    margin-bottom: 0.4rem;
    width: 100%;
}
.searchBooksSkeleton .items .link {
    height: 1.6rem;
    width: 50%;
}

.searchBooksSkeleton > .title > div,
.searchBooksSkeleton .items .image,
.searchBooksSkeleton .items .title,
.searchBooksSkeleton .items .link {
    background-color: var(--skeletonColor);
    border-radius: 0.4rem;
}
