.subscriptionsParam .notFoundMessage {
    font-style: italic;
    text-align: center;
}

.subscriptionsParam strong {
    display: block;
    margin: 1rem 0 0.5rem 0.8rem;
    font-size: 1.2rem
}

.subscriptionsParam ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.subscriptionsParam ul li {
    align-items: center;
    background: #ececec;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.8rem;
}

.subscriptionsParam ul li:nth-child(2n+1) {
    background: var(--nthChildLiBackground);
}

.subscriptionsParam ul a {
    color: var(--bodyColor);
    flex-grow: 1;
    text-decoration: none;
}

.subscriptionsParam ul button {
    background: #ed894b;
    border-radius: 0.4rem;
    border: 0;
    color: #fff;
    padding: 0.2rem 0.4rem;
}
